import React, { useRef } from 'react'
import logo from './images/icons8-cassette-100.png'
import './App.css'
import 'soundmanager2'
import { useEffect } from 'react'
import { TrackInfo } from './playlist-helpers'
import { useState } from 'react'
import { getTracksInfo } from './track-data'
import Player from './react-bar-ui/Player'
import { Sm2BarPlayer, Sm2SoundObject } from './react-bar-ui/bar-ui-helpers'
import { RateLimiter } from './common/rate-limiter'
import { backgroundImages } from './background-images'
import { asType } from './common/type-helpers'
import { useCallback } from 'react'
import shuffle from 'array-shuffle'

soundManager.debugMode = false

const playlistTitle = process.env.REACT_APP_MIXTAPE_TITLE
const playlistAuthor = process.env.REACT_APP_MIXTAPE_AUTHOR
const documentTitle = `${playlistTitle} ・ mixtape` || 'mixtape'

if (documentTitle) {
  document.title = documentTitle
}

let currentBackgroundImages: string[] = []

function getCurrentTrackInfo(player: Sm2BarPlayer, tracksInfo: TrackInfo[]) {
  const item = player.playlistController.getItem()
  const itemIndex = item ? player.playlistController.findOffsetFromItem(item) : -1
  if (itemIndex >= 0 && itemIndex < tracksInfo.length) {
    return tracksInfo[itemIndex]
  }
}

function setCurrentBackgroundImages(trackInfo: TrackInfo | undefined) {
  console.log('setCurrentBackgroundImages', trackInfo)
  currentBackgroundImages = [
    ...(trackInfo?.media?.sources || backgroundImages).filter(source =>
        !(trackInfo?.media?.excluded || []).includes(source)
    ),
  ]
  if (!trackInfo?.media?.strictOrder) {
    const deduped = new Set(currentBackgroundImages)
    currentBackgroundImages = shuffle(Array.from(deduped))
  }
}

function getNextBackgroundImage(first?: boolean) {
  console.log('getNextBackgroundImage', 'first', !!first)
  if (!first) {
    const lastImage = currentBackgroundImages.shift()
    if (lastImage) {
      currentBackgroundImages.push(lastImage)
    }
  }

  if (currentBackgroundImages.length) {
    const nextImage = currentBackgroundImages[0]
    return nextImage
  }
}

const doUpdateMediaSessionPlayState = asType<boolean>(false)
const doUpdateMediaSessionPosition = asType<boolean>(false)

type StorageData = {
  track?: {
    url: string
    index: number
    position: number
  },
  loopMode?: 'none' | 'all'
  skipped?: {
    index: number
    url: string
  }[]
}

function getStorageData() {
  const dataJson = localStorage.getItem('data')
  if (dataJson) {
    return asType<StorageData>(JSON.parse(dataJson))
  }
}

function saveStorageData(data: StorageData) {
  localStorage.setItem('data', JSON.stringify(data))
}

function clearStorageData() {
  localStorage.removeItem('data')
}

function updateStorageData(player: Sm2BarPlayer, tracksInfo: TrackInfo[]) {
  const data: StorageData = {
    loopMode: player.playlistController.data.loopMode ? 'all' : 'none',
  }
  const selectedItem = player.playlistController.getItem()
  const selectedIndex = selectedItem ? player.playlistController.findOffsetFromItem(selectedItem) : -1
  if (selectedIndex >= 0 && selectedIndex < tracksInfo.length) {
    const track = tracksInfo[selectedIndex]
    data.track = {
      index: selectedIndex,
      url: track.url,
      position: player.getPosition() || 0,
    }
  }
  data.skipped = Array.from(player.playlistController.data.playlist)
  .map((item, itemIndex) => ({ item: item as HTMLLIElement, itemIndex }))
  .filter(({ item, itemIndex }) => itemIndex <= tracksInfo.length && player.playlistController.isItemSkipped(item))
  .map(({ itemIndex }) => ({
    index: itemIndex,
    url: tracksInfo[itemIndex].url,
  }))

  saveStorageData(data)
}

function restorePlayerSession(player: Sm2BarPlayer, session: StorageData, tracksInfo: TrackInfo[]) {
  console.log('restorePlayerSession', session, tracksInfo)

  if (session.loopMode === 'all') {
    player.actions.repeat()
  }
  if (session.track) {
    const track = session.track

    // try to find the track in the current playlist
    // TODO: if there are duplicate tracks, pick the one closest to the original index
    const trackInfo = tracksInfo.find(trackInfo => trackInfo.url === track.url)
    if (trackInfo) {
      const trackInfoIndex = tracksInfo.indexOf(trackInfo)
      const item = player.playlistController.getItem(trackInfoIndex)
      if (item) {
        player.playlistController.select(item)
        if (track.position) {
          player.actions.setPosition(track.position)
        }
        player.actions.load()
      }
    }
  }
  if (asType<boolean>(true) && session.skipped) {
    session.skipped.forEach(track => {
      // try to find the track in the current playlist
      // TODO: if there are duplicate tracks, pick the one closest to the original index
      const trackInfo = tracksInfo.find(trackInfo => trackInfo.url === track.url)
      if (trackInfo) {
        const trackInfoIndex = tracksInfo.indexOf(trackInfo)
        const item = player.playlistController.getItem(trackInfoIndex)
        if (item) {
          player.playlistController.setItemSkipped(item, true)
        }
      }
    })
  }
}

const updateMediaControls = (player: Sm2BarPlayer) => {
  const mediaSession = navigator.mediaSession
  if (mediaSession) {
    let canGoNext = false
    if (player.playlistController.data.playlist.length) {
      if (player.playlistController.data.loopMode) {
        canGoNext = true
      } else {
        canGoNext = player.playlistController.data.selectedIndex < player.playlistController.data.playlist.length - 1
      }
    }

    mediaSession.setActionHandler('nexttrack', (canGoNext || null) && (() => {
      player.actions.next()
    }))

    let canGoBack = false
    if (player.playlistController.data.playlist.length) {
      if (player.playlistController.data.loopMode) {
        canGoBack = true
      } else {
        // can always go back to the beginning of the first track
        canGoBack = true
      }
    }

    /*
    mediaSession.setActionHandler('stop', (() => {
      player.actions.stop()
    }))
    */

    mediaSession.setActionHandler('previoustrack', (canGoBack || null) && (() => {
      player.actions.prev()
    }))

    mediaSession.setActionHandler('pause', () => {
      player.actions.pause()
    })

    mediaSession.setActionHandler('play', () => {
      // NOTE: these appear interchangable in terms of having the same effect
      player.actions.resume()
      //player.actions.play()
    })

    mediaSession.setActionHandler('seekto', details => {
      player.actions.setPosition(details.seekTime * 1000)
    })
  }
}

const mediaPositionRateLimiter = new RateLimiter({ delayInMillis: 1000 })

const updateMediaPosition = (soundObject: Sm2SoundObject) => {
  console.log('updateMediaPosition', soundObject.duration, soundObject.durationEstimate, soundObject.position)
  const mediaSession = navigator.mediaSession
  if (mediaSession) {
    mediaPositionRateLimiter.run({
      handler: () => {
        const duration = soundObject.duration ?? soundObject.durationEstimate
        mediaSession.setPositionState?.(duration ? {
          duration: duration / 1000,
          playbackRate: 1,
          position: soundObject.position / 1000,
        } : undefined)
      }
    })
  }
}

const storageUpdaterRateLimiter = new RateLimiter({ delayInMillis: 5000 })

let lastImageStart = 0
let lastImageStop = 0
const intervalTime = 7700

function App() {
  const [tracksInfo, setTracksInfo] = useState<TrackInfo[]>()
  const [backgroundImage, setBackgroundImage] = useState<string | undefined>()
  const [isPlaying, setIsPlaying] = useState(false)
  const isPlayingRef = useRef(false)
  const imageIntervalRef = useRef<NodeJS.Timeout>()
  const imageTimeoutRef = useRef<NodeJS.Timeout>()
  const playerRef = useRef<Sm2BarPlayer>()
  const player = playerRef.current
  const tracksInfoRef = useRef<TrackInfo[]>()
  const backgroundImageRef = useRef<string>()

  // TODO: specify type for e
  const togglePlay = useCallback((e?: any) => {
    if (e) {
      e.preventDefault()
    }
    const player = playerRef.current
    if (player) {
      player.actions.play()
    }
  }, [])

  const clearImageTimers = useCallback(() => {
    console.log('clearImageTimer',
        'imageIntervalRef', !!imageIntervalRef.current,
        'imageTimeoutRef', !!imageTimeoutRef.current,
    )
    lastImageStop = Date.now()
    if (imageTimeoutRef.current) {
      clearTimeout(imageTimeoutRef.current)
      imageTimeoutRef.current = undefined
    } else if (imageIntervalRef.current) {
      clearInterval(imageIntervalRef.current)
      imageIntervalRef.current = undefined
    }
    if (lastImageStart) {
      console.log('last image duration', lastImageStop - lastImageStart)
    }
  }, [])

  useEffect(() => {
    let canceled = false
    const beforeunload: (e: BeforeUnloadEvent) => any = e => {
      if (playerRef.current && tracksInfoRef.current) {
        updateStorageData(playerRef.current, tracksInfoRef.current)
      }

      if (asType<boolean>(false) && isPlayingRef.current) {
        const confirmationMessage = 'Leaving the site will stop the mixtape.'

        // Gecko + IE
        ;(e || window.event).returnValue = confirmationMessage

        // Gecko + Webkit, Safari, Chrome etc.
        return confirmationMessage
      }
    }
    const keydown: (e: KeyboardEvent) => any = e => {
      const player = playerRef.current
      console.log('keydown', e)

      const skimSize = 5000
      const largeSkimSize = skimSize * 4

      if (e.key === ' ') {
        togglePlay(e)
      } else if (e.key === 'ArrowLeft') {
        e.preventDefault()
        if (player) {
          player.actions.prev(e)
        }
      } else if (e.key === 'Enter') {
        e.preventDefault()
        if (player) {
          player.actions.setPosition(0)
        }
      } else if (e.key === 'ArrowRight') {
        e.preventDefault()
        if (player) {
          player.actions.next(e)
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault()
        if (player) {
          player.playlistController.playItemByOffset(0)
        }
      } else if (e.key === 'ArrowDown') {
        e.preventDefault()
        if (player) {
          player.playlistController.playItemByOffset(Math.max(0, player.playlistController.data.playlist.length - 1))
        }
      } else if ([',', '<'].includes(e.key)) {
        e.preventDefault()
        if (player) {
          const position = player.getPosition()
          if (typeof position === 'number') {
            const size = e.shiftKey ? largeSkimSize : skimSize
            player.actions.setPosition(Math.max(0, position - size))
          }
        }
      } else if (['.', '>'].includes(e.key)) {
        e.preventDefault()
        if (player) {
          const position = player.getPosition()
          const duration = player.getDuration()
          if (typeof position === 'number' && typeof duration === 'number') {
            const size = e.shiftKey ? largeSkimSize : skimSize
            player.actions.setPosition(Math.min(duration, position + size))
          }
        }
      }
    }

    const effect = async () => {
      let infos: TrackInfo[]
      try {
        infos = await getTracksInfo()
      } catch (e) {
        if (canceled) return
        console.error(e)
        // TODO: handle error in UI
        return
      }
      if (canceled) return
      tracksInfoRef.current = infos
      setTracksInfo(tracksInfoRef.current)

      window.addEventListener('beforeunload', beforeunload)
      document.addEventListener('keydown', keydown)

      let testChangeTracks = false
      if (testChangeTracks) {
        setTimeout(() => {
          tracksInfoRef.current = []
          setTracksInfo(tracksInfoRef.current)

          setTimeout(async () => {
            const data = await getTracksInfo()
            tracksInfoRef.current = data
            setTracksInfo(tracksInfoRef.current)
          }, 5000)

        }, 5000)
      }
    }
    effect()

    return () => {
      canceled = true
      clearImageTimers()
      document.removeEventListener('keydown', keydown)
      window.removeEventListener('beforeunload', beforeunload)
    }
  }, [togglePlay, clearImageTimers])

  useEffect(() => {
    const mediaSession = navigator.mediaSession
    if (mediaSession) {
      console.log('navigator.mediaSession', mediaSession)
    }

    const players = window.sm2BarPlayers
    console.log('players', players.length)

    if (tracksInfo) {

      const updateBackgroundImage = () => {
        console.log('updateBackgroundImage',
            'isPlaying', !!isPlayingRef.current,
        )

        if (!isPlayingRef.current) {
          return
        }

        backgroundImageRef.current = getNextBackgroundImage()
        setBackgroundImage(backgroundImageRef.current)
      }

      const startImageTimer = (reset?: boolean) => {
        const isFirstStart = lastImageStart === 0
        const resume = !reset && !isFirstStart
        console.log('startImageTimer', 'reset (param)', !!reset,
            'isFirstStart', isFirstStart,
            'resume', resume,
        )
        if (resume) {
          if (imageTimeoutRef.current || imageIntervalRef.current) {
            console.log('no need to resume since timers are already running')
            return
          }
          const lastImageDuration = lastImageStop - lastImageStart
          console.log('lastImageDuration', lastImageDuration)
          const lastImageTimeRemaining = Math.max(0, intervalTime - lastImageDuration)
          console.log('lastImageTimeRemaining', lastImageTimeRemaining)
          imageTimeoutRef.current = setTimeout(() => {
            updateBackgroundImage()
            console.log('starting imageInterval after imageTimeout expired')
            startImageTimer(true)
          }, lastImageTimeRemaining)
          lastImageStart = Date.now() - lastImageDuration
        } else {
          clearImageTimers()
          imageIntervalRef.current = setInterval(updateBackgroundImage, intervalTime)
          lastImageStart = Date.now()
        }
      }

      const seekMediaTrackIfNeeded = (trackInfo: TrackInfo | undefined, position: number) => {
        if (trackInfo?.media?.syncToStart) {
          clearImageTimers()

          // replay the sequence from the start to get to the right spot
          setCurrentBackgroundImages(trackInfo)
          const intervalCount = Math.floor(position / intervalTime)
          const howFarInTime = position - (intervalCount * intervalTime)
          for (let i = 0; i < intervalCount + 1; ++i) {
            backgroundImageRef.current = getNextBackgroundImage(i === 0)
          }
          setBackgroundImage(backgroundImageRef.current)

          lastImageStart = lastImageStop - howFarInTime
          console.log('intervals', intervalCount,
              'howFarInTime', howFarInTime,
          )
          if (isPlayingRef.current) {
            startImageTimer()
          }
          return true
        }
        return false
      }

      players.on = {
        loading: (player, url) => {
          console.log('players.on.loading', url)
          const decodedUrl = decodeURI(url)
          const trackInfo = tracksInfo.find(trackInfo => decodedUrl.includes(decodeURI(trackInfo.url)))
          false && console.log('trackInfo', trackInfo)
          if (mediaSession) {
            mediaSession.metadata = trackInfo ? new MediaMetadata({
              title: trackInfo.title,
              artist: trackInfo.artist || '(no artist info)',
              album: trackInfo.album,
              artwork: trackInfo.artwork || [
                {
                  src: `${process.env.PUBLIC_URL}/icons8-cassette-100.png`,
                  sizes: '100x100',
                  type: 'image/png',
                },
              ],
            }) : null
            console.log('change to mediaSession metadata', mediaSession.metadata)
            console.log('playlistData', player.playlistController.data)
          }
          if (doUpdateMediaSessionPosition) {
            navigator.mediaSession?.setPositionState?.(undefined)
          }
          updateMediaControls(player)
        },
        play: player => {
          console.log('players.on.play', 'wasPlaying', isPlayingRef.current)
          isPlayingRef.current = true
          setIsPlaying(isPlayingRef.current)

          const position = player.getPosition() || 0
          const trackInfo = getCurrentTrackInfo(player, tracksInfo)
          if (!seekMediaTrackIfNeeded(trackInfo, position)) {
            startImageTimer()
          }

          // TODO: change playbackState now or wait until whileplaying?
          if (navigator.mediaSession && doUpdateMediaSessionPlayState) {
            navigator.mediaSession.playbackState = 'playing'
          }
        },
        resume: (player, _soundObject) => {
          isPlayingRef.current = true
          setIsPlaying(isPlayingRef.current)

          const position = player.getPosition() || 0
          const trackInfo = getCurrentTrackInfo(player, tracksInfo)
          if (!seekMediaTrackIfNeeded(trackInfo, position)) {
            startImageTimer()
          }

          if (navigator.mediaSession && doUpdateMediaSessionPlayState) {
            navigator.mediaSession.playbackState = 'playing'
          }
          updateMediaControls(player)
        },
        whileplaying: (player, soundObject) => {
          if (asType<boolean>(false) && navigator.mediaSession && doUpdateMediaSessionPlayState) {
            navigator.mediaSession.playbackState = 'playing'
          }
          if (doUpdateMediaSessionPosition) {
            updateMediaPosition(soundObject)
          }

          storageUpdaterRateLimiter.run({
            handler: () => updateStorageData(player, tracksInfo),
          })
        },
        pause: (player, soundObject) => {
          isPlayingRef.current = false
          setIsPlaying(isPlayingRef.current)

          clearImageTimers()

          if (navigator.mediaSession && doUpdateMediaSessionPlayState) {
            navigator.mediaSession.playbackState = 'paused'
          }
          if (asType<boolean>(false) && doUpdateMediaSessionPosition) {
            updateMediaPosition(soundObject)
          }
          if (asType<boolean>(false)) {
            updateMediaControls(player)
          }
        },
        seek: (player, position) => {
          console.log('players.on.seek', position)
          const trackInfo = getCurrentTrackInfo(player, tracksInfo)
          seekMediaTrackIfNeeded(trackInfo, position)
        },
        end: player => {
          isPlayingRef.current = false
          setIsPlaying(isPlayingRef.current)

          clearImageTimers()

          // somehow the mediaSession knows it's over automatically at this point
          if (navigator.mediaSession && doUpdateMediaSessionPlayState) {
            navigator.mediaSession.playbackState = 'none'
            navigator.mediaSession.metadata = null
          }
          if (asType<boolean>(false)) {
            updateMediaControls(player)
          }
          updateStorageData(player, tracksInfo)
        },
        repeat: player => {
          console.log('players.on.repeat', player)
          updateMediaControls(player)
          updateStorageData(player, tracksInfo)
        },
        toggleSkipped: (player, itemIndex, skipped, item) => {
          console.log('players.on.toggleSkipped', player, itemIndex, skipped, item)
          updateStorageData(player, tracksInfo)
        },
        select: (player, itemIndex, item) => {
          console.log('players.on.select', player, itemIndex, item)
          updateStorageData(player, tracksInfo)
          const trackInfo = getCurrentTrackInfo(player, tracksInfo)
          setCurrentBackgroundImages(trackInfo)
          if (trackInfo?.media?.syncToStart || !backgroundImageRef.current ||
              (trackInfo?.media?.excluded || []).includes(backgroundImageRef.current)) {
            backgroundImageRef.current = getNextBackgroundImage(true)
            setBackgroundImage(backgroundImageRef.current)
          }
        }
      }
      return () => {
        window.sm2BarPlayers.on = null
      }
    }
  }, [tracksInfo, clearImageTimers])

  useEffect(() => {
    if (player && tracksInfo) {
      // restore last session
      const previousSession = getStorageData()
      if (previousSession) {
        restorePlayerSession(player, previousSession, tracksInfo)
      }
    }
  }, [tracksInfo, player])

  const subtitle = playlistTitle || `a mixtape for you${playlistAuthor && ` from ${playlistAuthor}`}`

  return (
    <>
      <div className="App">
        <header className="App-header">
          {(!isPlaying || !backgroundImage) ? (
            <>
              {playlistTitle && (
                <h5>
                  mixtape {playlistAuthor && ` from ${playlistAuthor}`} —
                </h5>
              )}
              {subtitle && (
                <h4>
                  {subtitle}
                </h4>
              )}
              <img
                src={logo}
                className="App-logo"
                alt="logo"
                onClick={togglePlay}
              />
            </>
          ) : (
            <img
              src={backgroundImage}
              className="App-logo"
              alt="logo"
              onClick={togglePlay}
            />
          )}
        </header>
      </div>
      <Player
        playerRef={playerRef}
        tracksInfo={tracksInfo || []}
        download={{
          addTrackNumberPrefix: true,
        }}
      />
    </>
  )
}

export default App
