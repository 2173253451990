import { TrackInfo } from './playlist-helpers'
import spongeBobMusic from './images/spongebob-music.gif'
import jakeTheDog from './images/jake-the-dog-tape-player-vertical.gif'
import { backgroundImages, generalBackgroundImages } from './background-images'
import { asType } from './common/type-helpers'

export async function getTracksInfo() {
  let tracksInfo: TrackInfo[] = []

  // TODO: load tracks info from somewhere
  tracksInfo.push(
    {
      url: `${process.env.PUBLIC_URL}/tracks/01 Joseph Smith's First Prayer.mp3`,
      title: `Joseph Smith's First Prayer`,
      artist: `InsideOut A Cappella`,
      album: `Reverence`,
      year: 2000,
      media: {
        sources: backgroundImages,
        syncToStart: true,
        strictOrder: true,
      },
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/03 Darwall_ Arise, O God, & Shine.mp3`,
      title: `Darwall: Arise, O God, & Shine`,
      artist: `Craig Jessop: Orchestra At Temple Square, Mormon Tabernacle Choir`,
      album: `The Sound Of Glory`,
      year: 2001,
      composer: 'John Darwall',
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/1-02 A Mighty Fortress_Fight the Good Fight.mp3`,
      title: `A Mighty Fortress/Fight the Good Fight`,
      artist: `BYU Men's Chorus`,
      album: `BYU Men's Chorus Archival 2005-2006 [Disc 1]`,
      year: 2006,
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/2-14 Marching for the Victory of Truth.mp3`,
      title: `Victory of Truth`,
      artist: 'Afterglow',
      album: `Songs From Young Women`,
    },
    /*
    {
      url: `${process.env.PUBLIC_URL}/tracks/as in adam.mp3`,
      title: `As In Adam`,
    },
    */
    {
      url: `${process.env.PUBLIC_URL}/tracks/05 Redeemer Of Israel.mp3`,
      title: `Redeemer Of Israel`,
      artist: `Mormon Tabernacle Choir`,
      album: `Come Come Ye Saints`,
      year: 1997,
      media: {
        excluded: [
          spongeBobMusic,
          jakeTheDog,
        ],
      }
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/05 O Savior, Thou Who Wearest A Crown.mp3`,
      title: `O Savior, Thou Who Wearest A Crown`,
      artist: `InsideOut A Cappella`,
      album: `Reverence`,
      year: 2000,
      media: {
        excluded: [
          spongeBobMusic,
          jakeTheDog,
        ],
      }
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/as in adam.mp3`,
      title: `As In Adam`,
      media: {
        excluded: [
          spongeBobMusic,
          jakeTheDog,
        ],
      }
    },
    /*
    {
      url: `${process.env.PUBLIC_URL}/tracks/05 Redeemer Of Israel.mp3`,
      title: `Redeemer Of Israel`,
      artist: `Mormon Tabernacle Choir`,
      album: `Come Come Ye Saints`,
      year: 1997,
    },
    */
    /*
    {
      url: `${process.env.PUBLIC_URL}/tracks/as in adam.mp3`,
      title: `As In Adam`,
    },
    */
    {
      url: `${process.env.PUBLIC_URL}/tracks/08 Growing Young.mp3`,
      title: `Growing Young`,
      artist: `Greg Simpson`,
      album: `Remember The Promise: Especially For Youth 2001`,
      year: 2001,
      media: {
        excluded: [
          spongeBobMusic,
        ],
      }
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/03 Brightly Beams Our Father's Mercy.mp3`,
      title: `Brightly Beams Our Father's Mercy`,
      artist: `InsideOut A Cappella`,
      album: `Reverence`,
      year: 2000,
      media: {
        excluded: [
          spongeBobMusic,
          jakeTheDog,
        ],
      }
    },
    /*
    {
      url: `${process.env.PUBLIC_URL}/tracks/What wondrous love is this solo.m4a`,
      title: `What Wondrous Love Is This`,
      year: 2019,
    },
    */
    {
      url: `${process.env.PUBLIC_URL}/tracks/1-03 Heaven's Reach.mp3`,
      title: `Strength Beyond My Own`,
      artist: `Tammy Simister Robinson`,
      album: `Seminary Music: Doctrine and Covenants`,
      media: {
        excluded: [
          spongeBobMusic,
          jakeTheDog,
        ],
      }
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/Se pudesses chegar pra Colobe.m4a`,
      title: `Se Pudesses Chegar Pra Colobe`,
      year: 2020,
      media: {
        excluded: [
          spongeBobMusic,
        ],
      }
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/1-12 Amazing Grace.mp3`,
      title: `Amazing Grace`,
      album: `Songs From Young Women`,
      media: {
        excluded: [
          spongeBobMusic,
        ],
      }
    },
    /*
    {
      url: `${process.env.PUBLIC_URL}/tracks/Se pudesses chegar pra Colobe.m4a`,
      title: `Se Pudesses Chegar Pra Colobe`,
      year: 2020,
    },
    */
    {
      url: `${process.env.PUBLIC_URL}/tracks/15 Trad_ Come, Thou Fount Of Every Blessing.mp3`,
      title: `Trad: Come, Thou Fount Of Every Blessing`,
      artist: `Craig Jessop: Orchestra At Temple Square, Mormon Tabernacle Choir`,
      album: `The Sound Of Glory`,
      year: 2001,
      media: {
        excluded: [
          spongeBobMusic,
          jakeTheDog,
        ],
      }
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/What wondrous love is this solo.m4a`,
      title: `What Wondrous Love Is This`,
      year: 2019,
      media: {
        excluded: [
          spongeBobMusic,
          jakeTheDog,
        ],
      }
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/1-07 I Know That My Redeemer Lives.mp3`,
      title: `I Know That My Redeemer Lives`,
      artist: `Linda Rowberry`,
      album: `EFY 2000: Forward With Faith`,
      year: 2000,
      media: {
        excluded: [
          spongeBobMusic,
          jakeTheDog,
        ],
      }
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/10 Faith In Every Footstep.mp3`,
      title: `Faith In Every Footstep`,
      artist: `Mormon Tabernacle Choir`,
      album: `Come Come Ye Saints`,
      year: 1997,
      media: {
        excluded: [
          spongeBobMusic,
        ],
      }
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/13 I Feel My Savior's Love.mp3`,
      title: `I Feel My Savior's Love`,
      artist: `InsideOut A Cappella`,
      album: `Reverence`,
      year: 2000,
      media: {
        excluded: [
          spongeBobMusic,
        ],
      }
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/1-06 Dance of Discovery.mp3`,
      title: `Dance of Discovery`,
      artist: `Mormon Tabernacle Choir`,
      album: `Light of the World: A Celebration of Life`,
      year: 2002,
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/11 Anon_ Simple Gifts.mp3`,
      title: `Anon: Simple Gifts`,
      artist: `Craig Jessop: Orchestra At Temple Square, Mormon Tabernacle Choir`,
      album: `The Sound Of Glory`,
      year: 2001,
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/05 Tender Mercies.mp3`,
      title: `Tender Mercies`,
      artist: `Shane Jackman`,
      album: `Remember The Promise: Especially For Youth 2001`,
      year: 2001,
      media: {
        excluded: [
          spongeBobMusic,
        ],
      }
    },
    {
      url: `${process.env.PUBLIC_URL}/tracks/2-08 He's Got The Whole World.mp3`,
      title: `He's Got The Whole World`,
      artist: `BYU Men's Chorus`,
      album: `BYU Men's Chorus Archival 2005-2006 [Disc 1]`,
      year: 2006,
    }
  )

  return tracksInfo.map(info => {
    return asType<TrackInfo>({
      ...info,
      media: {
        ...info.media,
        sources: info?.media?.sources || generalBackgroundImages,
      },
    })
  })
}
