import React, { useEffect, useMemo, useState } from 'react'
import {useRef} from 'react'
import 'soundmanager2'
import '../bar-ui/script/bar-ui'
import { Sm2BarPlayer } from './bar-ui-helpers'

type DownloadOptions = {
  addTrackNumberPrefix?: boolean
  openInSeparateWindow?: boolean
}

export default function Player(props: {
  playerRef?: React.MutableRefObject<Sm2BarPlayer | undefined>
  tracksInfo: TrackInfo[]
  playlistOpen?: boolean
  download?: boolean | DownloadOptions
}) {
  const {
    tracksInfo,
    playlistOpen,
    download,
    playerRef: playerRefFromProps,
  } = props

  const playerDivRef = useRef<HTMLDivElement>(null)
  const playerDiv = playerDivRef.current
  const playerRef = useRef<Sm2BarPlayer>()
  const player = playerRef.current
  const [sm2Ready, setSm2Ready] = useState(false)

  useEffect(() => {
    let canceled = false
    // TOOD: fix type
    // @ts-ignore
    window.soundManager.onready(() => {
      if (!canceled) {
        setSm2Ready(true)
      }
    })

    return () => {
      canceled = true
    }
  }, [])

  useEffect(() => {
    if (playerDiv && sm2Ready) {
      playerRef.current = window.sm2BarPlayers.addPlayer(playerDiv)
      if (playerRefFromProps) {
        playerRefFromProps.current = playerRef.current
      }
    }

    return () => {
      if (playerRef.current) {
        window.sm2BarPlayers.removePlayer(playerRef.current)
        playerRef.current = undefined
        if (playerRefFromProps) {
          playerRefFromProps.current = playerRef.current
        }
      }
    }
  }, [playerDiv, sm2Ready, playerRefFromProps])

  useEffect(() => {
    if (player) {
      player.playlistController.refresh()
    }
  }, [player, tracksInfo])

  const tracksInfoEnriched = useMemo(() => {
    return tracksInfo.map((trackInfo, trackIndex) => {
      let trackDownload: boolean | string | TrackItemDownloadOptions | undefined

      let filename = trackInfo.title
      if (trackInfo.artist) {
        filename = `${trackInfo.artist} - ${trackInfo.title}`
      }
      let doDownload: boolean | undefined
      let openInSeparateWindow: boolean | undefined
      let addTrackNumberPrefix: boolean | undefined

      // first apply global options
      if (typeof download === 'boolean') {
        doDownload = download
      } else if (download) {
        openInSeparateWindow = download.openInSeparateWindow
        addTrackNumberPrefix = download.addTrackNumberPrefix
        doDownload = true
      }

      // override with track options
      if (typeof trackInfo.download === 'boolean') {
        doDownload = trackInfo.download
      } else if (typeof trackInfo.download === 'string') {
        filename = trackInfo.download
        doDownload = true
      } else if (trackInfo.download) {
        openInSeparateWindow = trackInfo.download.openInSeparateWindow ?? openInSeparateWindow
        addTrackNumberPrefix = trackInfo.download.addTrackNumberPrefix ?? addTrackNumberPrefix
        doDownload = true
      }

      if (doDownload) {
        if (addTrackNumberPrefix) {
          const trackNumber = trackIndex + 1
          const trackNumberPadded = trackNumber.toString().padStart(tracksInfo.length.toString().length, '0')
          filename = `${trackNumberPadded} - ${filename}`
        }

        trackDownload = {
          filename,
          openInSeparateWindow,
        }
      }

      return {
        trackInfo,
        trackDownload,
      }
    })
  }, [tracksInfo, download])

  // fixed, bottom-aligned, full-width player
  return (
    <div
      className={[
        'sm2-bar-ui',
        'full-width',
        'fixed',
        ...playlistOpen ? [
          'playlist-open',
        ] : [],
      ].join(' ')}
      ref={playerDivRef}
    >
      <div className="bd sm2-main-controls">

        <div className="sm2-inline-texture" />
        <div className="sm2-inline-gradient" />

        <div className="sm2-inline-element sm2-button-element">
          <div className="sm2-button-bd">
            <a href="#play" className="sm2-inline-button sm2-icon-play-pause">Play / pause</a>
          </div>
        </div>

        <div className="sm2-inline-element sm2-inline-status">

          <div className="sm2-playlist">
            <div className="sm2-playlist-target">
              {/*
                <!-- playlist <ul> + <li> markup will be injected here -->
                <!-- if you want default / non-JS content, you can put that here. -->
              */}
              <noscript><p>JavaScript is required.</p></noscript>
            </div>
          </div>

          <div className="sm2-progress">
            <div className="sm2-row">
              <div className="sm2-inline-time">0:00</div>
              <div className="sm2-progress-bd">
                <div className="sm2-progress-track">
                  <div className="sm2-progress-bar" />
                  <div className="sm2-progress-ball"><div className="icon-overlay" /></div>
                </div>
              </div>
              <div className="sm2-inline-duration">0:00</div>
            </div>
          </div>

        </div>

        <div className="sm2-inline-element sm2-button-element sm2-volume">
          <div className="sm2-button-bd">
            <span className="sm2-inline-button sm2-volume-control volume-shade"></span>
            <a href="#volume" className="sm2-inline-button sm2-volume-control">volume</a>
          </div>
        </div>

        <div className="sm2-inline-element sm2-button-element">
          <div className="sm2-button-bd">
            <a href="#prev" title="Previous" className="sm2-inline-button sm2-icon-previous">&lt; previous</a>
          </div>
        </div>

        <div className="sm2-inline-element sm2-button-element">
          <div className="sm2-button-bd">
            <a href="#next" title="Next" className="sm2-inline-button sm2-icon-next">&gt; next</a>
          </div>
        </div>

        <div className="sm2-inline-element sm2-button-element">
          <div className="sm2-button-bd">
            <a href="#repeat" title="Repeat playlist" className="sm2-inline-button sm2-icon-repeat repeat">&infin; repeat</a>
          </div>
        </div>

        {/* <!-- not implemented -->
        <div className="sm2-inline-element sm2-button-element disabled">
          <div className="sm2-button-bd">
            <a href="#shuffle" title="Shuffle" className="sm2-inline-button sm2-icon-shuffle">shuffle</a>
          </div>
        </div>
        */}

        <div className="sm2-inline-element sm2-button-element sm2-menu">
          <div className="sm2-button-bd">
            <a href="#menu" className="sm2-inline-button sm2-icon-menu">menu</a>
          </div>
        </div>

      </div>

      <div className="bd sm2-playlist-drawer sm2-element">

        <div className="sm2-inline-texture">
          <div className="sm2-box-shadow" />
        </div>

        {/* playlist content is mirrored here */}

        <div className="sm2-playlist-wrapper">

          <ul className="sm2-playlist-bd">
            {tracksInfoEnriched.map(({ trackInfo, trackDownload }, trackIndex) => (
              <TrackItem
                key={`${trackIndex}-${trackInfo.url}`}
                trackInfo={trackInfo}
                download={trackDownload}
              />)
            )}
          </ul>

        </div>

      </div>
    </div>
  )
}

type TrackItemDownloadOptions = Pick<DownloadOptions, 'openInSeparateWindow'> & {
  filename?: string
}

function TrackItem(props: {
  trackInfo: TrackInfo
  download?: boolean | string | TrackItemDownloadOptions
}) {
  const {
    trackInfo: { url, artist, title },
    download: downloadOptions,
  } = props

  let download: true | string | undefined
  let openInSeparateWindow = false
  if (downloadOptions === true) {
    download = true
  } else if (typeof downloadOptions === 'string') {
    download = downloadOptions
  } else if (downloadOptions) {
    download = downloadOptions.filename || true
    openInSeparateWindow = !!downloadOptions.openInSeparateWindow
  }

  return (
    <li>
      <div className="sm2-row">
        <div className="sm2-col sm2-wide">
          <a href={url}>{artist && <><b>{artist}</b> - </>}{title}</a>
        </div>
        {download && (
          <div className="sm2-col">
            <a
              href={url}
              {...openInSeparateWindow ? {
                target: '_blank',
              } : {
                download,
              }}
              title={`${openInSeparateWindow ? 'Open' : 'Download'} "${title}"`}
              className="sm2-icon sm2-music sm2-exclude"
            >Download this track</a>
          </div>
        )}
      </div>
    </li>
  )
}

export type TrackInfo = {
  url: string
  title: string
  artist?: string
  download?: string | boolean | DownloadOptions
}
