import jakeTheDog from './images/jake-the-dog-tape-player-vertical.gif'
import leavesFalling from './images/VapidWhichAnole-max-1mb.gif'
import saltLakeTemple from './images/salt-lake-city-temple-flowers.gif'
import spongeBobMusic from './images/spongebob-music.gif'
import lightThroughTrees from './images/light-through-trees-above.gif'
import jesusSunset from './images/jesus-sunset.gif'
import butterfliesTrees from './images/butterflies-trees.gif'
import treesMist from './images/trees-mist.gif'
import treesSunShiftFast from './images/trees-sun-shift-fast.gif'
import lightAbove from './images/light-above.gif'
import treeLightPan from './images/trees-light-pan.gif'
import sunFogTrees from './images/sun-fog-trees.gif'
import firstVision from './images/first-vision.gif'
import josephKneeling from './images/joseph-kneeling.gif'
import morningWaterfalls from './images/morning-waterfalls.gif'

export const backgroundImages: string[] = [
  saltLakeTemple,

  spongeBobMusic,
  butterfliesTrees,
  sunFogTrees,
  treeLightPan,

  leavesFalling,
  jakeTheDog,
  treesSunShiftFast,
  leavesFalling,

  josephKneeling,
  lightAbove,
  lightThroughTrees,
  jesusSunset,
  treesMist,

  firstVision,
  firstVision,
  firstVision,
  jesusSunset,
  jesusSunset,
]

export const generalBackgroundImages: string[] = [
  saltLakeTemple,

  spongeBobMusic,

  sunFogTrees,
  treeLightPan,

  leavesFalling,
  jakeTheDog,
  leavesFalling,

  lightAbove,
  lightThroughTrees,
  treesMist,

  firstVision,
  firstVision,
  jesusSunset,
  jesusSunset,

  morningWaterfalls,
]
